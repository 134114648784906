import React from "react"

import Layout from "../components/layout"
import Profile from "../components/profile"

import Seo from "../components/seo"

const Contact = ({ location }) => {
  return (
    <Layout>
      <Seo
        pagetitle="お問い合わせ"
        pagedesc="ご質問やお問い合わせはコチラからどうぞ。"
        pagepath={location.pathname}
      />
      <div className="contact">
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeU19My58VgXlF-_PLFvWYfd5TBiekjLNqmMuCfRsmHPn96WQ/viewform?embedded=true" title="contact" marginHeight={0} marginWidth={0} className="contactForm" width="100%" height={900} frameBorder={0}>読み込んでいます…</iframe>
        <Profile />
      </div>
    </Layout>
  )
}

export default Contact
